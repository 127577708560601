<template>
    <div>
        <BaseSectionNetworkValue />
        <SectionCandidate />
        <SectionCompany />

        <div class="row">
            <div class="col text-center">
                <div class="pb-0" style="margin: 20px 0; color: black">
                    <p>&#9426; 2020 Refair.me</p>
                    <a
                        href="https://www.freepik.com/free-photos-vectors/business"
                        style="color: lightgray; font-size: 10px"
                        >Business vector created by Pikisuperstar - Freepik.com</a
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { BaseSectionNetworkValue } from '@/components'
import { SectionCandidate, SectionCompany } from './components'
import { mapState, mapGetters } from 'vuex'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import {
    faWrench,
    faSlidersH,
    faMoneyBillAlt,
    faUsers,
    faLaptopCode,
    faTrophy,
    faUserTie,
    faMapMarkerAlt,
    faEuroSign,
    faUser as fasUser,
} from '@fortawesome/free-solid-svg-icons'
import { ROUTE } from '@/enums'

export default {
    name: 'Home',

    components: { BaseSectionNetworkValue, SectionCandidate, SectionCompany },

    computed: {
        mapMarker: () => faMapMarkerAlt,
        euroSign: () => faEuroSign,
    },

    mounted() {
        const animateHTML = function () {
            let elems
            let windowHeight
            function init() {
                elems = document.querySelectorAll('.to-be-animated')
                windowHeight = window.innerHeight
                addEventHandlers()
                checkPosition()
            }
            function addEventHandlers() {
                window.addEventListener('scroll', checkPosition)
                window.addEventListener('resize', init)
            }
            function checkPosition() {
                for (let i = 0; i < elems.length; i++) {
                    const positionFromTop = elems[i].getBoundingClientRect().top
                    if (positionFromTop - windowHeight <= 0) {
                        elems[i].classList.add('animate-line')
                    }
                }
            }
            return {
                init: init,
            }
        }
        animateHTML().init()
    },

    data() {
        return {
            ROUTE,
            matchedCompanies: [
                {
                    logo: 'datawalk.png',
                    title: 'Senior Front-End Dev (13k)',
                    faces: ['face3.jpg', 'face4.jpg', 'face1.png'],
                },
                {
                    logo: 'techsorted.png',
                    title: 'Front-End Developer (9k)',
                    faces: ['face3.jpg', 'face5.jpg'],
                },
                {
                    logo: 'oanda.png',
                    title: 'Front-End Developer (9k)',
                    faces: ['face2.png', 'face1.png'],
                },
                {
                    logo: 'd3group.png',
                    title: 'Javascript Developer (8k)',
                    faces: ['face6.jpg', 'face7.jpg'],
                },
            ],
        }
    },
}
</script>
<style lang="sass" scoped>
.main-panel
    width: 100%

    +on-md-and-up
        padding-bottom: 100px

.search-panel
    margin: 50px 10px 10px 10px

    +on-md-and-up
        margin: 140px 50px 0 0

.header-first
    margin: 20px
    +on-md-and-up
        margin: 100px 0 0 100px

.marginator
    margin-top: 16px
    +on-md-and-up
        margin-top: 0

.employer-box
    margin-top: 20px
    +on-md-and-up
        margin-top: 0
        margin-left: 200px

.network-box
    margin: 20px
    +on-md-and-up
        margin: 100px

.network-box-images
    margin-left: 40px
    +on-md-and-up
        margin-left: 180px

.smarter-way-box
    overflow: hidden
    padding-left: 0
    +on-md-and-up
        padding-left: 40px
        overflow: visible

.matched-jobs-box
    padding: 0 -60px
    +on-md-and-up
        padding: 0
        width: 430px

.card-header
    color: $lightAccent
    background-color: white

svg
    color: $primaryColor

h1,h2,h3,h4,h5
    color: $lightAccent

    svg
        color: $lightAccent

.col-4
    .card
        cursor: pointer

.shadow
    box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1)
    border: 0

.card-featured
    color: white

.pointer
    cursor: pointer

.circuit
    background: url('~@/assets/circuit.png')
    background-repeat: repeat

.blue-border-circle
    border-radius: 50%
    border: 2px solid #42bff4

.animate
    animation: shadow-pulse 2s infinite

.animate-line
    fill-opacity: 0
    stroke-dasharray: 870
    stroke-dashoffset: 870
    animation: draw 4s forwards ease-out

@keyframes draw
    to
        stroke-dashoffset: 0

@keyframes shadow-pulse
     0%
        box-shadow: 0 0 0 0px rgba(66, 191, 244, 0.2)
     100%
        box-shadow: 0 0 0 15px rgba(66, 191, 244, 0)
</style>
